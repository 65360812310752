var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vx-card',{attrs:{"title":"Edit Visit Plan"}},[_c('div',{staticClass:"vx-row mb-6",staticStyle:{"width":"50%"}},[_c('vs-button',{staticClass:"ml-4 mt-2",attrs:{"color":"danger","icon-pack":"feather","icon":"icon-arrow-left"},on:{"click":function($event){return _vm.handleBack()}}},[_vm._v("Back")])],1),_c('div',{staticClass:"vx-row mb-6",staticStyle:{"width":"50%"}},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full flex items-center"},[_c('span',[_vm._v("Territory")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full"},[_c('vs-input',{staticClass:"w-full",attrs:{"readonly":"true","value":this.selectedTerritory
            ? this.selectedTerritory.code + ' ' + this.selectedTerritory.name
            : ''}})],1)]),_c('div',{staticClass:"vx-row mb-6",staticStyle:{"width":"50%"}},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full flex items-center"},[_c('span',[_vm._v("Route Plan")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full"},[_c('vs-input',{staticClass:"w-full",attrs:{"readonly":"true","value":this.selectedRoutePlan
            ? this.selectedRoutePlan.Code +
              ' ' +
              this.selectedRoutePlan.ScheduleType
            : ''}})],1)]),_c('div',{staticClass:"vx-row mb-6",staticStyle:{"width":"50%"}},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full flex items-center"},[_c('span',[_vm._v("Route Assignment")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full"},[_c('vs-input',{staticClass:"w-full",attrs:{"readonly":"true","value":this.selectedRouteAssignment
            ? this.selectedRouteAssignment.Code
            : ''}})],1)]),_c('div',{staticClass:"vx-row mb-6",staticStyle:{"width":"50%"}},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full flex items-center"},[_c('span',[_vm._v("Date")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full"},[_c('vs-input',{staticClass:"w-full",attrs:{"readonly":"true","value":this.dateFrom != this.dateTo
            ? this.dateFrom + ' - ' + this.dateTo
            : this.dateFrom}})],1)]),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col sm:w-1/1 w-full mb-base"},[_c('l-map',{staticStyle:{"height":"400px"},attrs:{"zoom":_vm.zoom,"center":_vm.center}},[_c('l-tile-layer',{attrs:{"url":_vm.url,"attribution":_vm.attribution}}),_vm._l((_vm.customerAddresses),function(customerAddress,index){return _c('l-marker',{key:index,attrs:{"lat-lng":customerAddress.latLng}},[_c('l-tooltip',{attrs:{"options":{ permanent: true, interactive: true }}},[_c('div',[_c('b',[_vm._v(_vm._s(customerAddress.label))]),_c('br'),_vm._v(_vm._s(customerAddress.address)+" ")])])],1)})],2)],1)]),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col sm:w-1/1 w-full mb-base"},[_c('vs-table',{attrs:{"data":this.data,"border":1},scopedSlots:_vm._u([{key:"default",fn:function({ data }){return _vm._l((data),function(tr,indexParent){return _c('tr',{key:indexParent,attrs:{"data":tr}},[_c('td',{staticClass:"td vs-table--td",attrs:{"width":"100px"}},[_vm._v(" "+_vm._s(data[indexParent].date)+" ")]),_c('td',{staticClass:"td vs-table--td",attrs:{"width":"200px"}},[_c('v-select',{attrs:{"disabled":data[indexParent].is_pulled,"filterable":false,"options":data[indexParent].option_personal},on:{"input":(val) => {
                    _vm.onChangePersonal(val, indexParent);
                  },"search":(search, loading) => {
                    _vm.onSearchPersonal(search, loading, indexParent);
                  }},model:{value:(data[indexParent].selected_personal),callback:function ($$v) {_vm.$set(data[indexParent], "selected_personal", $$v)},expression:"data[indexParent].selected_personal"}})],1),_c('td',{attrs:{"colspan":"6"}},[_c('table',[_c('draggable',{attrs:{"list":data[indexParent].customer_lines,"tag":"tbody"}},_vm._l((data[indexParent]
                      .customer_lines),function(child,indexChild){return _c('tr',{key:indexChild},[_c('td',{staticClass:"td vs-table--td",attrs:{"width":"400px"}},[_c('v-select',{attrs:{"label":"id","disabled":data[indexParent].is_pulled,"filterable":false,"options":data[indexParent].customer_lines[indexChild]
                            .option_customer},on:{"input":(val) => {
                            _vm.onChangeCustomer(val, indexParent, indexChild);
                          },"search":(search, loading) => {
                            _vm.onSearchCustomer(
                              search,
                              loading,
                              indexParent,
                              indexChild
                            );
                          }},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" "+_vm._s(option.name)+" ")]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" "+_vm._s(option.name)+" ")]}}],null,true),model:{value:(
                          data[indexParent].customer_lines[indexChild]
                            .selected_customer
                        ),callback:function ($$v) {_vm.$set(data[indexParent].customer_lines[indexChild]
                            , "selected_customer", $$v)},expression:"\n                          data[indexParent].customer_lines[indexChild]\n                            .selected_customer\n                        "}})],1),_c('td',{attrs:{"width":"100px"}},[_c('input',{staticClass:"vs-inputx vs-input--input normal",staticStyle:{"border":"1px solid rgba(0, 0, 0, 0.2)"},attrs:{"type":"text","readonly":"readonly"},domProps:{"value":_vm.getCustomerCategoryName(
                            data[indexParent].customer_lines[indexChild]
                              .selected_customer
                          )}})]),_c('td',{attrs:{"width":"400px"}},[_c('v-select',{attrs:{"label":"id","disabled":data[indexParent].is_pulled,"filterable":false,"options":data[indexParent].customer_lines[indexChild]
                            .option_customer_address},on:{"input":(val) => {
                            _vm.onChangeCustomerAddress(
                              val,
                              indexParent,
                              indexChild
                            );
                          },"search":(search, loading) => {
                            _vm.onSearchCustomerAddress(
                              search,
                              loading,
                              data[indexParent].customer_lines[indexChild]
                                .selected_customer.id,
                              indexParent,
                              indexChild
                            );
                          }},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" "+_vm._s(option.contact_name)+" ")]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" "+_vm._s(option.contact_name)+" ")]}}],null,true),model:{value:(
                          data[indexParent].customer_lines[indexChild]
                            .selected_customer_address
                        ),callback:function ($$v) {_vm.$set(data[indexParent].customer_lines[indexChild]
                            , "selected_customer_address", $$v)},expression:"\n                          data[indexParent].customer_lines[indexChild]\n                            .selected_customer_address\n                        "}})],1),_c('td',{attrs:{"width":"400px"}},[_c('input',{staticClass:"vs-inputx vs-input--input normal",staticStyle:{"border":"1px solid rgba(0, 0, 0, 0.2)"},attrs:{"type":"text","readonly":"readonly"},domProps:{"value":_vm.getCustomerAddressAddress(
                            data[indexParent].customer_lines[indexChild]
                              .selected_customer_address
                          )}})]),_c('td',{staticClass:"td vs-table--td",staticStyle:{"text-align":"center"},attrs:{"width":"200px"}},[_c('v-select',{attrs:{"disabled":data[indexParent].is_pulled,"filterable":true,"options":_vm.optionSalesTask,"placeholder":"Default","multiple":""},model:{value:(
                          data[indexParent].customer_lines[indexChild]
                            .selected_sales_task
                        ),callback:function ($$v) {_vm.$set(data[indexParent].customer_lines[indexChild]
                            , "selected_sales_task", $$v)},expression:"\n                          data[indexParent].customer_lines[indexChild]\n                            .selected_sales_task\n                        "}})],1),_c('td',{staticClass:"td vs-table--td",staticStyle:{"text-align":"center"},attrs:{"width":"50px"}},[_c('arrow-up-down-bold',{attrs:{"size":15}})],1),_c('td',{staticClass:"td vs-table--td",staticStyle:{"text-align":"center"},attrs:{"width":"75px"}},[_c('div',{staticClass:"vx-input-group flex"},[(
                            data[indexParent].customer_lines.length > 1 &&
                            !data[indexParent].is_pulled
                          )?_c('vs-button',{staticStyle:{"margin-right":"5px"},attrs:{"size":"small","color":"danger","icon-pack":"feather","icon":"icon-trash","title":"Remove Customer"},on:{"click":function($event){$event.stopPropagation();return _vm.removeCustomerRow(indexParent, indexChild)}}}):_vm._e(),(
                            data[indexParent].customer_lines.length - 1 ==
                              indexChild && !data[indexParent].is_pulled
                          )?_c('vs-button',{attrs:{"size":"small","color":"success","icon-pack":"feather","icon":"icon-plus","title":"Add Customer"},on:{"click":function($event){$event.stopPropagation();return _vm.addCustomerRow(indexParent)}}}):_vm._e()],1)])])}),0)],1)])])})}}])},[_c('template',{slot:"thead"},[_c('vs-th',{attrs:{"width":"100px"}},[_vm._v("Date")]),_c('vs-th',{attrs:{"width":"200px"}},[_vm._v("Sales")]),_c('vs-th',{attrs:{"width":"400px"}},[_vm._v("Customer")]),_c('vs-th',{attrs:{"width":"100px"}},[_vm._v("Dstr Channel")]),_c('vs-th',{attrs:{"width":"400px"}},[_vm._v("Customer Ship-to")]),_c('vs-th',{attrs:{"width":"400px"}},[_vm._v("Ship-to Address")]),_c('vs-th',{attrs:{"width":"200px"}},[_vm._v("Task")]),_c('vs-th',{attrs:{"width":"50px"}})],1)],2)],1)]),_c('div',{staticClass:"vx-row mb-6",staticStyle:{"width":"50%"}},[_c('div',{staticClass:"vx-col sm:w-1/1 w-full"},[_c('div',{staticStyle:{"position":"absolute","left":"50%","-ms-transform":"translate(-50%, -50%)","transform":"translate(-50%, -50%)"}},[_c('vs-button',{staticClass:"mr-3 mb-2",on:{"click":_vm.handleSubmit}},[_vm._v("Submit")]),_c('vs-button',{staticClass:"ml-4 mt-2",attrs:{"type":"border","color":"danger"},on:{"click":_vm.handleBack}},[_vm._v("Back")])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }